import request from '@/auth/jwt/useJwt'

// 修改密码
export function passwordEdit(oldPassword, newPassword, newPassword1) {
  return request.axiosIns({
    url: '/apollo-ucenter/update-password',
    method: 'POST',
    params: {
      oldPassword,
      newPassword,
      newPassword1,
    },
  })
}

// 获取个人信息
export function detail() {
  return request.axiosIns({
    url: '/apollo-ucenter/detail',
    method: 'get',
  })
}
